import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HomeImage from '../components/Images/HomeImage';


const MaisonPage = () => (
  <Layout>
    <SEO title="L'esprit de la maison" />

    <div className="content page">

      <h1>L'esprit de la maison</h1>
      <div className="home-paragraph">
        <div>
          <p>
          Lié(e) est une maison parisienne créée en 2019, qui réalise des bijoux destinés à symboliser l’amour d’un duo, d’un trio, d’une tribu.
            <br /><br />
          Lié(e) est une family brand pour offrir, porter et partager son amour en alliance – mais au poignet ! Une famille existe dès que l’on s’aime, quel que soit son sexe, ses origines et son passé.
            <br /><br />
          Chez Lié(e), le bijou ne se transmet pas de génération en génération, il est identitaire, unique et gravé : on le porte chacun à sa façon, mais ensemble. En deux, trois, quatre exemplaires, il peut même devenir l’emblème précieux d’une famille entière.
            <br /><br />
          On offre un bijou Lié(e), à l’occasion d’un anniversaire d’amour ou d’amitié, d’un baptême, d’une célébration… Sur un coup de cœur !  Il signe un duo parrain, marraine et filleul(e), il se partage entre frères et sœurs, parents, beaux-parents et enfants, meilleur(e)s ami(e)s, ou lignées de mamans et de papas et enfin, pour tous les amoureux de la planète.
            <br /><br />
          Lié(e) sera le lien d’amour que vous aurez choisi.
          </p>
        </div>
      </div>
    </div>
    
    <div className="home-2-images">
      <div>
        <HomeImage image="artisanat2" />
      </div>
      <div>
        <HomeImage image="artisanat1" />
      </div>
      <div>
        <HomeImage image="artisanat3" />
      </div>
    </div>

    <div className="content">
      <div className="home-paragraph">
        <div>
          <h2>Un état d’esprit 100% made in France et éthique</h2>
          <p>
            Réalisées dans un atelier joaillier parisien, en plein cœur du quartier
            Drouot, les créations Lié(e) sont entièrement façonnées à la main dans
            la pure tradition joaillière et sont, par conséquent, made in France.
            Chaque étape est pensée dans un souci éthique de la confection du
            bijou à son enveloppe. <br /> <br />
            Le choix d’un écrin naturel fabriqué par une ONG soutenant le travail des
            femmes en Inde, s’est imposé naturellement, afin de respecter à la fois
            l’esthétique de Lié(e) et les problématiques éco-responsables actuelles.
          </p>
        </div>
      </div>
    </div>

    <div className="home-2-images">
      <div>
        <HomeImage image="artisanat4" />
      </div>
    </div>
  </Layout>
);

export default MaisonPage;
